<template>

 <div class="col-12"> 
      <div class="table-responsive">
        <br>
        <h4> デバイス情報 </h4>
         <span> ID :{{ device.node_id }} </span> <br>
         <span > ParentID : {{ device.parent_node_id }}</span > <br>
         <span > GatewayID : {{ device.connection_device_id }}</span > <br>
         <span> Name :{{ device.name }} </span> <br>
         <span > Type : {{ device.sensor_type }}</span > <br>
         <br>
         <h4>ゲートウェイの状態 </h4>
         <span > Timestamp :    {{ moment( device.timestamp , 'YYYY/MM/DD, hh:mm:ss A')  }} </span > <br>
         <span > State : {{ device.state }}</span > <br>
         
         <br>
         <h4>最後に到達したデータ</h4>
         <span > data1 : {{ device.data1 }}</span > <br>
         <span > data2 : {{ device.data2 }}</span > <br>
         <span > data3 : {{ device.data3 }}</span > <br>
         <span > battery : {{ device.battery }}</span > <br>
         <span > cdata : {{ device.cdata }}</span > <br>
         <span > status : {{ device.alive }}</span > <br>
         <span > datetime :  {{ moment(  new Date( Number(device.datetime)) , 'YYYY/MM/DD, hh:mm:ss A')  }}</span > <br>
         <span >sensor_time :  {{ moment( new Date(Number(device.sensor_time)) , 'YYYY/MM/DD, hh:mm:ss A')  }}</span > <br>
      </div>
</div>
</template>

<script>

import { faEye , faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { defineComponent } from "vue";

import axios from 'axios';
import moment from "moment";

export default defineComponent({
  name: "Snow",
 // components:  {  } ,
  data : function() {return { 
  isSharedKeyChecked : false ,
  device: {}
  } },
  computed: {},
  mounted : async function(){
     this.$emit('show-header');
      console.log( this.$route.params);
    this.device = this.$route.params ; 
    console.log(this.device );
 },
  methods: {
        moment(date, format) { 
        return moment(date).local().format(format)
        } 
    }
});

</script>
.container {
  width: 200px;
  margin: 30px;
}

.input {
  width: 100%;
  margin: 0px;
  padding-right: 5px;
}

.eye::after {
  font-family: 'FontAwesome';
  content: "\f06e";
}

.eye-slash::after {
  font-family: 'FontAwesome';
  content: "\f070";
}

.input-icon {
  position: relative;
  float: right;
  margin-top: -25px;
}